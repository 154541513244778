.modal-addUser .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

  /* Why are you doing like this? */ 
/* @media(max-width: 767px) {
    .sc-kgoBCf.gaEXFy, 
    .ant-drawer-body{
        padding: 0
    }
} */

