#pageDetailDrawer {
  .ant-drawer-close {
    height: 70px;
    z-index: 999;
  }
  .ant-drawer-title {
    height: 40px;
    line-height: 40px;
  }
  .explorer-title {
    font-weight: bolder;
    font-size: 22px;
  }
  .ant-steps-item-content {
    width:auto;
  }
  .ant-upload.ant-upload-drag {
    background: #d2f1f7;
  }
  .ant-upload-list-item-name {
    color:#F26524;
  }
}

@primary-color: #F26524;@link-color: #1da57a;