.drawerfeedbackReport .splitpanel-horizontal {
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.drawerfeedbackReport .splitpanel-horizontal-mobile {
    top: 0;
    left: 50%;
    width: 100%;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
}

.drawerfeedbackReport .splitpanel-horizontal-mobile .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    padding: 0 !important;
}

#export-button {
    margin-left: 5px;
    background: none;
    outline: none;
    border: none;
}

.toggleFullScreen {
    margin-right: 56px;
    position: absolute;
    right: 0;
    width: 30px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: absolute;
    top: 0;
    display: block;
    width: 30px;
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
}

.toggleFullScreen:hover, .toggleFullScreen:focus {
    color: rgba(0, 0, 0, 0.75);
}

.feedbackReport-spiner {
    position: fixed;
    top: 50%;
    left: 50%;
}

.feedbackReport-checkList {
    margin-right: 8px;
}

.topicPhase-table-outer {
    display: flex;
}

.feedbackReport-projectName {
    font-size: 2.1rem;
    font-family: Calibri, sans-serif;
}

.feedbackReport-projectName span {
    font-weight: bold;
    font-size: 2.3rem;
}

.feedbackReport-projectName span, .special-link .link-to-project {
    font-family: Calibri, sans-serif;
}

.tablefeedbackReport {
    position: relative;
}

.drawerfeedbackReport .export-btn {
    color: #000;
    z-index: 9;
    background-color: #95de64;
}

.feedbackReport-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedbackReport-btn-wrapper{
    margin-right: 44px;
    display: flex;
    align-items: center;
}

.ios-device{
    margin-right: 60px !important;
}

.feedbackReport-btn-outer button {
    margin-left: 15px;
    width: 85px;
}
.minimize button {
    width: 32px !important;
}

.feedbackReport-btn-outer .print-btn {
    background-color: #f0f0f0;
}

.feedbackReport-header {
    margin-bottom: 30px;
}

.special-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}