.w100 {
    width: 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

.feedback-editor-drawer .ant-drawer-body {
    margin-right: 0;
    padding: 0;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
}

.dragger-upload {
    max-width: 150px;
    height: 150px;
}

.dragger-upload .ant-upload-drag-container {
    width: 150px;
    height: 150px;
}