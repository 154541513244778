.modal-addUser .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.organization-modal {
    padding: 0;
    margin-top: 0;
}

.editProject-modal .ant-modal-body{
    padding: 0;
}