.closeLicenceDrawer {
    cursor: pointer;
    position: absolute;
    right: 15px;
}

.closeLicenceDrawer:hover,
.assignLicense-action:hover{
    color: #F26524;
    transition: color 300ms ease-in-out;
}

.assignLicense-action{
    width: 100%;
}