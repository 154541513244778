/* .gantt-container {
  height: calc(100vh - 50px - 200px);
} */

.now {
  cursor: ew-resize;
  z-index: 10000;
}

.next {
  background-color: green !important;
  opacity: 0%;
}

.previous {
  background-color: blue !important;
  opacity: 0%;
}

.action-btn-wrapper {
  text-align: center;
  font-size: x-large;
}

.gantt_tooltip {
  z-index: 9999;
  display: inline-block;
}

.gantt_section_time {
display: inline-block;
}
.gantt_section_time select {
  padding: 6px 2px;
}
/* grid button icon */
.fa {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  opacity: 0.2;
  padding: 5px;
}

.fa:hover {
  opacity: 1;
}

.fa-pencil {
  color: #ffa011;
}

.fa-plus {
  color: #328ea0;
}

.fa-times {
  color: red;
}

/* ------- */
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gantt_control {
  background: #ededed;
  text-align: center;
}

.gantt_control input[type='button'],
.gantt_control input[type='file'],
.gantt_control input[type='checkbox'],
.gantt_control button {
  font: 500 14px Roboto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fff;
  padding: 4px 12px;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
}

.gantt_control input[type='button']:hover,
.gantt_control button:hover {
  border: 1px solid #b3b3b3;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.gantt_control input[type='button']:active,
.gantt_control button:active {
  background: #f7f7f7;
}

.gantt_control input[type='button']:focus,
.gantt_control button:focus {
  outline: none !important;
}

.gantt_control {
  padding: 10px 0 12px;
}

.gantt_control input[type='radio'],
.gantt_control input[type='checkbox'] {
  display: none;
}

.gantt_control label {
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.54);
  font: 14px Roboto;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.material-icons {
  position: relative;
  top: 6px;
  right: 2px;
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.icon_color {
  color: #0288d1;
}

.material-icons.md-inactive {
  color: rgba(0, 0, 0, 0.38);
}

.checked_label {
  color: rgba(0, 0, 0, 0.7) !important;
}

.gantt_radio:checked,
.gantt_radio:not(:checked) {
  position: absolute;
  left: -9999px;
}
.gantt_radio:checked + label,
.gantt_radio:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.gantt_radio:checked + label:before,
.gantt_radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.gantt_radio:checked + label:after,
.gantt_radio:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #8a9ada;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.gantt_radio:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.gantt_radio:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* config for material theme */

.gantt_task_line .gantt_task_progress_drag {
  bottom: -10px;
  height: 16px;
  margin-left: -8px;
  width: 16px;
}

.gantt_cal_light {
  height: fit-content !important;
}

.gantt_cal_cover {
  z-index: 9999;
}

.gantt_cal_ltitle {
  padding: 12px 25px !important;
  cursor: move !important;
  background-color: #EFF3FF;
  border-bottom: 1px solid #D0DBE3;
}

.ant-dropdown-menu-submenu-popup{
  z-index: 10001;     
}

/* Style the line div */
.drag-gantt-line {
  position: absolute;
  top: 50%; /* Center the line vertically */
  left: 50%; /* Center the line horizontally */
  transform: translate(-50%, -50%); /* Adjust position based on size */
  width: 2px; /* Set the width of the line */
  height: 100%; /* Set the height of the line to cover the container */
  background-color: black; /* Set the color of the line */
}

.middle {
  opacity: 0%;
  z-index: 9999;
}

.hidden {
  opacity: 0%;
}

.today{
  background-color: #604fff !important;
  z-index: 9999;
}

.color_selector_wrapper{
  display: flex;
  align-items: center;
  gap: 5px;

  .alpha_value{
    width: 50px;
    text-align: center;
  }
}

.checkbox-babel{
  margin-left: 5px;
}

.gantt_wrap_section {
  border-bottom: 0 !important;
  padding: 5px 0;
}
.dhx_cal_ltext {
  display: flex;
  margin-top: 8px;
}
.dhx_cal_ltext input {
    font-family: Roboto, arial, sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    height: 100%;
    outline: none !important;
    padding: 5px 8px;
}

.gantt_cal_ltext > label > input[type=checkbox] { 
  margin-right: 5px;
  margin-top: 5px;
}

.right {
  text-align: right /* only needed for the table version */
}

.flex {
  display: flex;
  justify-content: space-between;
}

.gantt_task_line.gantt_bar_project, .gantt_task_line.gantt_bar_task  {
  min-width: 80px;
}

#gantt_here {
  height:calc(100vh - 130px) !important;
}

.gantt_cal_light textarea, .gantt_cal_light input, .gantt_cal_light select {
  font-weight: normal;
}

.gantt_section_resources > .gantt_cal_ltext {
  width: 100%;
}

.gantt_cal_light.gantt_cal_light_full {
  min-width: 780px;
}
.resource_marker{
  text-align: center;
}
.resource_marker div{
  width: 28px;
  height: 28px;
  border-radius: 15px;
  color: #FFF;
  margin: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.resource_marker.workday_ok div {
  background: #1BC297;
}

.resource_marker.workday_over div{
  background: #E3334E;
}

.owner-label {
  width: 20px;
  height: 20px;  
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 25px;
  background: #e6e6e6;
  color: #6f6f6f;
  margin: 0 3px;
  font-weight: bold;
}

.folder_row .gantt_tree_content {
  font-weight: bold;
}

.highlighted_resource,
.highlighted_resource.odd {
  background-color: rgba(255, 251, 224, 0.6);
}

.group_row,
.group_row.odd,
.gantt_task_row.group_row {
  background-color: rgba(232, 232, 232, 0.6);
}

.resource-select-panel .gantt_layout_content {
  line-height: 35px;
  text-align: right;
  padding-right: 15px;
  overflow: hidden !important;
}
.resource-select {
  padding: 3px;
  margin-left: 13px;
}

.resource-controls .gantt_layout_content{
  padding: 7px;
  overflow: hidden;
}
.resource-controls label{
  margin: 0 10px;
  vertical-align: bottom;
  display: inline-block;
  color: #3e3e3e;
  padding: 2px;
  transition: box-shadow 0.2s;
}

.resource-controls label:hover{
  box-shadow: 0 2px rgba(84, 147, 255, 0.42);
}

.resource-controls label.active,
.resource-controls label.active:hover
{
  box-shadow: 0 2px #5493ffae;
  color: #1f1f1f;
}

.resource-controls input{
  vertical-align: top;
  margin: 5px 3px 0 5px;
}
.column_overload .gantt_histogram_fill {
  background-color:color-mix(in srgb, var(--dhx-gantt-base-colors-error), transparent 60%);
}

.gantt_cal_lsection {
  margin-top: 6px !important;
  display: inline-block;
  min-width: 15%;
  text-align: right;
  vertical-align: top;
}
.gantt_cal_ltext, .dhx_cal_ltext, .gantt_section_resources {
  display: inline-block;
  width: 85%;
}

#tree_select_btn, #saved_query_select_btn {
  padding: 3px;
}
.dhx_modal_cover, .gantt_modal_box {
  z-index: 99999 !important;
}

.gantt_cal_ltext label {
 font-weight: normal;
}

.gantt_critical_task {
  --dhx-gantt-task-background: #E3334E !important;
}