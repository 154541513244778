.ant-calendar {
    bottom: 42px;
}

.ant-slider-tooltip {
    z-index: 9999;
}

.timeSearch{
  display: flex;
   align-items: center;
   margin-bottom: 20px
}

.timeSearch-title{
    margin-right: 10px;
    width: 100px;
}

.timeSearch-input{
    width: 70px;
    margin-left: 10px;
    text-align: center !important;
}

.timeSearch-inputError {
    width: 70px;
    margin-left: 10px;
    text-align: center !important;
    border-color: red;
}

.timeSearch-datePicker{
 margin-right: 20px;   
}

.clear-timeSearch{
    background-color: #F26524;
    color: #fff
}

.clear-timeSearch:hover{
    background-color: #ff5000;
    color: #fff
}

.disable-event{
    pointer-events: none;
}

.close-alert{
    position: absolute;
    top: 5px;
    right: 8px;
    color: red;
    z-index: 999;
}

.hidden {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
}

.show{
    opacity: 1;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
}

.default{
    z-index: 99;
    position: fixed;
    right: 0;
    top: 0;
}


/* custom datepicker on mobile */
.ant-picker.slider-datepicker-mobile{
    padding: 0;
    border-radius: 3px;
    border: 1px solid #000;
}

.slider-datepicker-mobile input{ 
    display: none;
}

.slider-datepicker-mobile .ant-picker-suffix{
    margin: 0;
}

.slider-datepicker-mobile .ant-picker-suffix .anticon svg{
    height: 30px;
    width: 30px;
    color: #000;
}


/* Swap footer calendar position */
.slider-timepicker .ant-picker-footer .ant-picker-ranges {
    display: flex;
    justify-content: space-between;
}

.slider-timepicker .ant-picker-footer ul .ant-picker-now{
    order: 2;
}

.slider-timepicker .ant-picker-footer ul .ant-picker-ok{
    order: 1;
}