.topicReportTable td, .topicReportTable th{
    padding: 10px;
    font-family: Calibri, sans-serif;
    word-break: break-all;
}

.topic-tag .tag-item,
.checklist-item{
    font-family: Calibri, sans-serif;

}

 .topicReportTable td{
    border: 1px solid #000;
}

.topicReportTable table {
    margin-bottom: 40px;
    width: auto;
}

.topicReportTable .topic,
.topicReportTable .topic-title{
    background-color: rgb(189, 215, 238);
    text-align: center;
}

.topicReportTable .data,
.topicReportTable .data-title{
    background-color: rgb(255, 230, 153);
    text-align: center;
}

.topicReportTable .phase,
.topicReportTable .phase-title{
    background-color: rgb(198, 224, 180);
    text-align: center;
}

.topicReportTable .topic, 
.topicReportTable .data,
.topicReportTable .phase {
    font-size: 1.8rem;
    font-weight: bold;
}

.topic-title,
.data-title,
.phase-title{
    font-weight: 600;
    font-size: 1.5rem;
}   

.topic-name{
    width: 150px;
}

.topic-description{
    width: 300px;
}

.topic-type{
    width: 80px;
}

.topic-priority{
    width: 80px;
}

.topic-status{
    width: 80px;
}

.topic-tag{
    width: 80px;
}

.topic-folder,
.topic-file{
    width: 200px;
}

.topic-assign{
    width: 150px;
}
.topic-completed{
    width: 120px;
}

.topic-checklist {
    width: 250px;
}

.topicReport-workflowName{
    font-size: 1.8rem;
    font-weight: bold;
    background-color: #bfbfbf;
    padding: 3px 7px;
}

/* th.topicReport-workflowName:nth-child(n+2),
.topicReport-topicPhases:nth-child(n+2){
    display: none;
}  */

