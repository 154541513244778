/*html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    background: #000;
    font-size: 15px;
    font-family: @default-font;
}*/
.full-window {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -ms-transition: left 0.25s ease-out;
  -o-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.transparent-to-input {
  pointer-events: none;
}
.opaque-to-input {
  pointer-events: auto;
}
.clickable {
  cursor: pointer;
}
/*a {
    text-decoration: none;
    color: @highlight-color;
}*/
a:hover {
  text-decoration: underline;
}
/*
@modal-background-color: @panel-background-color;
@modal-text-color: @panel-emphasized-text-color;
@modal-header-background-color: rgba(0,0,0,0.2);
@modal-header-text-color: @panel-emphasized-text-color;*/
/*.modal-background {
    .opaque-to-input;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;  required for IE9 
}*/
/*
.modal {
    position: absolute;
    margin: auto;
    background-color: @modal-background-color;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 100%;
    max-width: 100%;
    font-family: @default-font;
    color: @modal-text-color;
}

.modal-header {
  background-color: @modal-header-background-color;
  border-bottom: @panel-element-border;
  font-size: 15px;
  line-height: 40px;
  margin: 0;
}

.modal-header h1 {
  font-size: 15px;
  color: @modal-header-text-color;
  margin-left: 15px;
}*/
/* Commented out due to conflicts with client apps. 
.modal-content {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  overflow: auto;
}*/
/*.modal-close-button {
    position: absolute;
    right: 15px;
    cursor: pointer;
    font-size: 18px;
    color: @modal-header-text-color;
}*/
#ui {
  z-index: 2100;
}
@media print {
  .full-window {
    position: initial;
  }
}
/* input[type=text] {
  height: 38px;
  background-color: #eeeeee;
  color: @input-text-color;
  font-size: 14px;
}

::-webkit-input-placeholder {
  color: fade(@input-text-color, 75%);
  font-style: italic;
}

:-moz-placeholder { /* Firefox 18- 
  color: fade(@input-text-color, 75%);
  font-style: italic;
}

::-moz-placeholder {  /* Firefox 19+  
  color: fade(@input-text-color, 75%);
  font-style: italic;
}

:-ms-input-placeholder {
  color: fade(@input-text-color, 75%);
  font-style: italic;
}

input:focus {
    outline-color: #FFFFFF;
}
*/
/*select {
  display: block;
  background-color: @panel-form-input-background-color;
  color: @panel-form-input-text-color;
  height: 40px;
  border: 0;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 5px;
}*/
.markdown img {
  max-width: 100%;
}
.markdown svg {
  max-height: 100%;
}
.markdown input,
.markdown select,
.markdown textarea,
.markdown fieldset {
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}
.markdown label {
  vertical-align: middle;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: .5em;
}
.markdown h1 {
  font-size: 2rem;
}
.markdown h2 {
  font-size: 1.5rem;
}
.markdown h3 {
  font-size: 1.25rem;
}
.markdown h4 {
  font-size: 1rem;
}
.markdown h5 {
  font-size: 0.875rem;
}
.markdown h6 {
  font-size: 0.75rem;
}
.markdown p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.markdown strong {
  font-weight: bold;
}
.markdown em {
  font-style: italic;
}
.markdown small {
  font-size: 80%;
}
.markdown mark {
  color: #000;
  background: #ff0;
}
.markdown u {
  text-decoration: underline;
}
.markdown s {
  text-decoration: line-through;
}
.markdown dl,
.markdown ol,
.markdown ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.markdown ol {
  list-style: decimal inside;
}
.markdown ul {
  list-style: disc inside;
}
.markdown pre,
.markdown code,
.markdown samp {
  font-family: monospace;
  font-size: inherit;
}
.markdown pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow-x: scroll;
}
.markdown a {
  color: #68ADFE;
  text-decoration: none;
}
.markdown a:hover {
  text-decoration: underline;
}
.markdown pre,
.markdown code {
  background-color: transparent;
  border-radius: 3px;
}
.markdown hr {
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.125);
}
.markdown .left-align {
  text-align: left;
}
.markdown .center {
  text-align: center;
}
.markdown .right-align {
  text-align: right;
}
.markdown .justify {
  text-align: justify;
}
.markdown .truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.markdown ol.upper-roman {
  list-style-type: upper-roman;
}
.markdown ol.lower-alpha {
  list-style-type: lower-alpha;
}
.markdown ul.circle {
  list-style-type: circle;
}
.markdown ul.square {
  list-style-type: square;
}
.markdown .list-reset {
  list-style: none;
  padding-left: 0;
}
.floating {
  pointer-events: auto;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(47, 53, 60, 0.8);
}
.floating-horizontal {
  pointer-events: auto;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(47, 53, 60, 0.8);
  padding-left: 5px;
  padding-right: 5px;
}
.floating-vertical {
  pointer-events: auto;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(47, 53, 60, 0.8);
  padding-top: 5px;
  padding-bottom: 5px;
}
@media print {
  .floating {
    display: none;
  }
}
.distance-legend {
  pointer-events: auto;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(47, 53, 60, 0.8);
  padding-left: 5px;
  padding-right: 5px;
  right: 25px;
  bottom: 30px;
  height: 30px;
  width: 125px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: content-box;
}
.distance-legend-label {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: lighter;
  line-height: 30px;
  color: #ffffff;
  width: 125px;
  text-align: center;
}
.distance-legend-scale-bar {
  border:none;
  position: absolute;
  height: 10px;
  top: 15px;
}
@media print {
  .distance-legend {
    display: none;
  }
}
@media screen and (max-width: 520px), screen and (max-height: 420px) {
  .distance-legend {
    /* display: none; */
    width: 45px;
    height: 25px;
  }
  .distance-legend .distance-legend-label{
    font-size: 9px;
    width: 100%;
    position: relative;
    top: -2px;
  }
}
.navigation-controls {
  position: absolute;
  right: 30px;
  top: 210px;
  width: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 300;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.navigation-control {
  cursor: pointer;
  border-bottom: 1px solid #555555;
}
.naviagation-control:active {
  color: #FFF;
}
.navigation-control-last {
  cursor: pointer;
  border-bottom: 1px solid #555555;
  border-bottom: 0;
}
.navigation-control-icon-zoom-in {
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  padding-bottom: 4px;
}
.navigation-control-icon-zoom-out {
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}
.navigation-control-icon-reset {
  position: relative;
  left: 10px;
  width: 10px;
  height: 10px;
  fill: rgba(255, 255, 255, 0.8);
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: content-box;
}
.compass {
  pointer-events: auto;
  position: absolute;
  right: 8px;
  top: 50px;
  width: 95px;
  height: 95px;
  overflow: hidden;
}
.compass-outer-ring {
  position: absolute;
  top: 0;
  width: 95px;
  height: 95px;
  fill: rgba(255, 255, 255, 0.5);
}
.compass-outer-ring-background {
  position: absolute;
  top: 14px;
  left: 14px;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  border: 12px solid rgba(47, 53, 60, 0.8);
  box-sizing: content-box;
}
.compass-gyro {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 95px;
  height: 95px;
  fill: #CCC;
}
.compass-gyro-active {
  fill: #68adfe;
}
.compass-gyro-background {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 33px;
  height: 33px;
  border-radius: 33px;
  background-color: rgba(47, 53, 60, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: content-box;
}
.compass-gyro-background:hover + .compass-gyro {
  fill: #68adfe;
}
.compass-rotation-marker {
  position: absolute;
  top: 0;
  width: 95px;
  height: 95px;
  fill: #68adfe;
}

@media print {
  .navigation-controls {
    display: none;
  }
  .compass {
    display: none;
  }
}
