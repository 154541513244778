.dataAttribute-modal{
    color: #fff;
    width: 370px;
}

.dataAttribute-modal .ant-modal-body{
    background-color: #303336;
}

.dataAttribute-modal .ant-modal-content{
    width: 370px;
    border-radius: 5px;
    margin: auto;
}

.dataAttribute-modal .anticon svg{
    color: #fff;
}

.dataAttribute-modal .anticon svg:hover{
    color: #2089cb;
}

.dataAttribute-modal .title{
    color: #fff;
}

.dataAttribute-modal ul {
    list-style-type: disc;
}

.dataAttribute-modal ul li {
    list-style: inside;
    word-break: break-all;
    padding-bottom: 6px;
}

.dataAttribute-modal ul li a {
    color: #fff;
    text-decoration: none;
}