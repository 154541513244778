.feedBackReportTable td, .feedBackReportTable th {
    padding: 10px;
    font-family: Calibri, sans-serif;
    word-break: break-all;
    border: 1px solid black;
    border-collapse: collapse;
}

.no-data {
    color: #ff0000;
}

.feedBackReportTable td {
    border: 1px solid #000;
}

.feedBackReportTable {
    padding: 0 20px 0 0;
}

.feedBackReportTable table {
    margin-bottom: 40px;
    width: 100%;
}

.feedBackReportTable .topic, .feedBackReportTable .topic-title {
    background-color: rgb(189, 215, 238);
    text-align: center;
}

.feedBackReportTable .data, .feedBackReportTable .data-title {
    background-color: rgb(255, 230, 153);
    text-align: center;
}

.feedBackReportTable .phase, .feedBackReportTable .phase-title {
    background-color: rgb(198, 224, 180);
    text-align: center;
}

.feedBackReportTable .topic, .feedBackReportTable .data, .feedBackReportTable .phase {
    font-size: 1.8rem;
    font-weight: bold;
}

.topic-title, .data-title, .phase-title {
    font-weight: 600;
    font-size: 1.5rem;
}