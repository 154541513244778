.topicEditorDrawer.custom-wraper-splitPanel .ant-drawer-body{
    margin-bottom: 50px;
}

.topicEditorDrawer .ant-table-tbody tr td {
    width: 150px;
}

.topicEditorDrawer .ant-table-tbody tr td:last-child {
    width: 80px;
    text-align: center;
}