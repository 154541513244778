.button-tool {
    margin-right: 8px;
}
.input-group{
    display: flex !important;
    width: 100% !important;
    .ant-select-arrow {
        // top: 36% !important;
    }
}
.flex-center {
    display: flex !important;
    align-items: center;
    width: 100%;
}

.ant-radio-wrapper {
    margin-right: 0
}
span.ant-radio + * {
    padding-right: 0 !important;
}
@primary-color: #F26524;@link-color: #1da57a;