body {
    background: #f0f2f5;
}
.checkbox-status .ant-form-item-control-input-content {
    flex: none !important;
}

.checkbox-status {
    font-size: 20px;
}

.select-user-wrap {
    display: flex;
    flex-wrap: nowrap;
}

.select-user-wrap .user-select {
    flex-grow: 1;
}

.user-btn_add {
    display: flex;
    align-items: center;
}

.user-btn_add button {
    border-left: none;
}

.modal-add-user .ant-modal-footer {
    text-align: right;
}

.modal-add-user .ant-modal-footer {
    display: none;
}

.user-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.form-control {
    background: none;
    outline: none;
}

.organization-user-item .btn.btn-link {
    border: none;
    background: none;
    outline: none;
    padding: 4px 0 4px 10px;
}

.organization-user-list {
    padding: 20px 0;
}

.organization-user-list .organization-user-item .ant-row.ant-form-item {
    margin-bottom: 5px;
}

.form-username, .form-username:focus, .form-username:target {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}

.site-form-item-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.organization-user-item .ant-select-selector {
    height: 28px;
}

.custom-input .ant-select-selector {
    height: 40px !important;
}

.custom-input .ant-select-selection-item {
    line-height: 40px !important;
}

.ant-menu-vertical, .ant-layout-sider .ant-menu-inline{
    border: none;
}
@media screen and (max-width: 767px) {
    .content-left {
        margin-right: 0px !important;
    }
    .control-btn-group {
        padding: 5px 0;
    }
    .control-btn-group .ant-btn {
        padding: 2px 15px;
        height: 28px;
    }
}

.table-wrapper .ant-table-tbody tr td {
    padding: 5px 16px;
}

.table-wrapper .ant-form-item {
    margin-bottom: 0;
}

.sider-menu-layout {
    background: #fff;
    min-height: 100vh;
    position: fixed;
    border-right: 1px solid rgb(221, 221, 221);
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
}

.sider-menu-layout .ant-layout-sider-trigger {
    top: 7rem;
    right: -13px;
    border-radius: 50%;
    position: absolute;
    width: 25px !important;
    height: 25px;
    line-height: 25px;
    background-color: #d3dbe3;
}

.sider-menu-layout .ant-layout-sider-trigger:hover {
    background-color: #ff8d4f;
}

.sider-menu-layout.ant-layout-sider-has-trigger {
    padding-bottom: 0 !important;
}

/* .ant-layout-sider-children {
    overflow: hidden;
    outline: none;
}

.ant-layout-sider-children:hover {
    overflow-y: scroll;
} */

.ant-layout-sider-children {
    height: 100%;  
    display: flex;
    flex-direction: column;
}
.sidebar-minimizer{
    position:relative;
    flex:0 0 50px;
    cursor:pointer;
    background-color: #fff;
    border-top: 1px solid;
    border: 0;
    border-top: 1px solid #ddd;
}
.sidebar-minimizer.focus,.sidebar-minimizer:focus{outline:0}
/* .sidebar-minimizer:hover{background-color:rgba(0,0,0,.3)}    */
.sidebar-minimizer .anticon-left {
    position:absolute;    
    right:0;
    width:50px;
    top: 17px;
}
.sidebar-minimizer .anticon-right {
    width: 100%;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #F26524;
    font-weight: bold;
}
.ant-menu-inline .ant-menu-item-selected::after {
   border-right: none;    
}
.menu-item-logo-mobile{
    padding: 0 !important;
}
.scroll-bars-menu-mobile  .sider-menu-layout {
    width: 0px !important;
    min-width: 0px !important;
}
.scroll-bars-menu-mobile   .sidebar-minimizer{
    display: none;
}