.arrow-back:hover {
    color: #F26524;
}

.arrow-back {
    margin-right: 10px;
}

.closeOrganizationButton {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}

.closeOrganizationButton:hover {
    color: #F26524;
    transition: color 300ms ease-in-out;
}

.organizationPanel {
    right: 0;
    top: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.btnOrganizationPanel {
    position: absolute;
    top: 50%;
    right: -15px;
    padding: 0 !important;
    z-index: 999;
}

.ant-modal-wrap.antd-img-crop-modal {
    z-index: 99999;
}

.modal-organization .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 0;
}

.distance-limit-input.ant-input-number{
    width: 100% !important;
}

.navigation-underground .ant-form-item-control-input-content{
    display: flex;
    justify-content: space-between;
  }