.drawerTopicReport  .splitpanel-horizontal {
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      cursor: n-resize;
      padding: 4px 0 0;
      z-index: 100;
      position: absolute;
      border-top: 1px solid #ddd;
      background-color: #f2cfc4;
}

.drawerTopicReport   .splitpanel-horizontal-mobile {
    top: 0;
    left: 50%;
    width: 100%;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
}

.drawerTopicReport .splitpanel-horizontal-mobile  .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    padding: 0 !important;
}

.toggleFullScreen {
    margin-right: 56px;
    position: absolute;
    right: 0;
    width: 30px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: absolute;
    top: 0;
    display: block;
    width: 30px;
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    text-rendering: auto;

  }

.toggleFullScreen:hover,
.toggleFullScreen:focus{
    color: rgba(0, 0, 0, 0.75);
}

.topicReport-spiner{
    position: fixed;
    top: 50%;
    left: 50%;
}

.topicReport-checkList{
    margin-right: 8px;
}

.topicPhase-table-outer{
    display: -webkit-box;      
    display: -moz-box;         
    display: -ms-flexbox;    
    display: -webkit-flex;     
    display: flex;             
}

.topicReport-projectName{
    font-size: 2.1rem;
    font-family: Calibri, sans-serif;
}

.topicReport-projectName span{
    font-weight: bold;
    font-size: 2.3rem;
}

.topicReport-projectName span,
.special-link .link-to-project{
    font-family: Calibri, sans-serif;
}



.tableTopicReport {
    position: relative;
}

.drawerTopicReport .export-btn{
    color: #000;
    z-index: 9;
    background-color: #95de64;
}

.topicReport-outer{
    display: -webkit-box;      
    display: -moz-box;         
    display: -ms-flexbox;      
    display: -webkit-flex;     
    display: flex;           
    justify-content: space-between; 
    align-items: center;
}

.topicReport-btn-wrapper{
    margin-right: 44px;
    display: flex;
    align-items: center;
}

.ios-device{
    margin-right: 60px !important;
}

.topicReport-btn-outer button{
    margin-left: 15px;
    width: 85px;
}

.minimize button {
    width: 32px !important;
}

.topicReport-btn-outer .print-btn{
    background-color: #f0f0f0;
}

.topicReport-header{
    margin-bottom: 30px;
}

.special-link{
    display: -webkit-box;      
    display: -moz-box;         
    display: -ms-flexbox;      
    display: -webkit-flex;     
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;   
}