.img-xdtwin-Guide {
    width: 100%;
    height: 100%;
    background-size: cover !important;
}

.disableOk .ant-modal-footer .ant-btn.ant-btn-primary {
    background-color: rgb(229, 229, 229);
    border-color: rgb(229, 229, 229);
    pointer-events:none;
}

.XDTwinGuideModalPC{
    padding-bottom: 0 !important;
    width: calc(100vw - 50%) !important;
}
.modal-xdtwin-guide .ant-modal-body {
    height: calc(100vw - 100vw*(70/100));
}
.XDTwinGuideModalMobile{
    padding-bottom: 0 !important;
    width: calc(100vw - 10%) !important;
}

@media only screen and (max-height: 800px) {
    .modal-xdtwin-guide .ant-modal-body {
        height: calc(100vh - 100vh*(30/100));
    }
    .XDTwinGuideModalMobile{
        width: calc(100vw - 25%) !important;
    }
}

@media only screen and (max-height: 300px) {
    .modal-xdtwin-guide .ant-modal-body {
        height: calc(100vh - 100vh*(40/100));
        padding: 10px;
    }
    .XDTwinGuideModalMobile{
        width: calc(100vw - 10%) !important;
    }
}
@media only screen and (min-width: 2000px) {
    .modal-xdtwin-guide .ant-modal-body {
        height: calc(100vw - 100vw*(65/100));
    }
}

@media only screen and (max-width: 900px) {
    .modal-xdtwin-guide .ant-modal-body {
        padding: 10px;
        height: calc(100vh - 100vh*(60/100));
    }
    .XDTwinGuideModalMobile{
        padding-bottom: 0 !important;
        width: calc(100vw - 30%) !important;
    }
    .modal-xdtwin-guide .ant-modal-close-x{
        width: 30px;
    }
}
@media only screen and (max-width: 420px) {
    .XDTwinGuideModalMobile{
        padding-bottom: 0 !important;
        width: calc(100vw - 10%) !important;
    }
    .modal-xdtwin-guide .ant-modal-body {
        padding: 10px;
        height: calc(100vh - 100vh*(70/100));
    }
}
@media (max-width: 767px){
    .modal-xdtwin-guide .ant-modal {
        max-width: calc(100vw - 16px);
        margin: 0px auto;
    }
    
}
.footer-modal{
    display: flex;
    justify-content: space-between;
} 
.modal-xdtwin-guide .ant-modal-close-x{
    line-height: 35px;
    width: 36px;
}