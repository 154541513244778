.checkbox-status .ant-form-item-control-input-content {
    flex: none !important;
}

.checkbox-status {
    font-size: 20px;
}

.select-user-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
}

.select-user-wrap .user-select {
    flex-grow: 1;
}

.user-btn_add {
    display: flex;
    align-items: center;
}

.user-btn_add button {
    border-left: none;
}

.modal-add-user .ant-modal-footer {
    text-align: right;
}

.modal-add-user .ant-modal-footer {
    display: none;
}

.user-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.form-control {
    background: none;
    outline: none;
}

.organization-user-item .btn.btn-link {
    border: none;
    background: none;
    outline: none;
    padding: 4px 0 4px 10px;
}

.organization-user-list {
    padding: 20px 0;
}

.organization-user-list .organization-user-item .ant-row.ant-form-item {
    margin-bottom: 5px;
}

.form-username, .form-username:focus, .form-username:target {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}

.site-form-item-icon {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-right: 5px;
}

.organization-user-item .ant-select-selector {
    height: 28px;
}

.custom-input .ant-select-selector {
    height: 40px !important;
}

.custom-input .ant-select-selection-item {
    line-height: 40px !important;
}

@media screen and (max-width: 767px) {
    .content-left {
        margin-right: 0px !important;
    }
    .control-btn-group {
        padding: 5px 0;
    }
    .control-btn-group .ant-btn {
        padding: 2px 15px;
        height: 28px;
    }
}

.table-wrapper .ant-table-tbody tr td {
    padding: 5px 16px;
}

.table-wrapper .ant-form-item {
    margin-bottom: 0;
}

.sider-menu-layout .ant-layout-sider-trigger {
    top: 7rem;
    right: -13px;
    border-radius: 50%;
    position: absolute;
    width: 25px !important;
    height: 25px;
    line-height: 25px;
    background-color: #d3dbe3;
}

.sider-menu-layout .ant-layout-sider-trigger:hover {
    background-color: #ff8d4f;
}

.ant-form-item .ant-upload.ant-upload-drag {
    min-width: 150px;
    max-width: 300px;
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
}

.license-group {
    align-items: flex-end;
}

.license-group .ant-form-item {
    margin-bottom: 0px !important;
}

/* .dont-show .ant-select-selection-item {
    display: none !important;
  } */

  #json-langeditor *{ font-family : monospace !important;font-size: 16px !important;direction:ltr !important;text-align:left !important;}
