.attributeModal .ant-modal-content{
    border-radius: 10px;
}

.attributeModal .ant-modal-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.headingAttribute__title{
    line-height: 30px;
}

.headingAttribute__title, 
.headingAttribute__item {
    /* margin: auto; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.attribute__line{
    display: flex;  
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.attribute__firstLine{
    padding: 8px;
}

.attribute__secondLine{
    font-size: 1.5rem;
    padding: 3px 8px;
}

.attribute__thirdLine {
    font-size: 1.3rem;
    padding-top: 8px;
    margin-left: 8px;
}

/* .childAttribute{
    margin-left: 10px;
} */

.attributeModal .splitpanel{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}