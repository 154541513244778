.arrow-back:hover{
    color: #F26524;
}

.arrow-back {
    margin-right: 10px;
}

.closeOrganizationButton {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}
.closeOrganizationButton:hover{
    color: #F26524;
    transition: color 300ms ease-in-out;
}

.organizationPanel {
    right: 0;
    top: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.btnOrganizationPanel {
    position: absolute;
    top: 50%;
    right: -15px;
    padding: 0 !important;
    z-index: 999;
}

.ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open{
    width: auto;
}

.organization-detail-custom .ant-drawer-body{
    padding: 0;
}

.organization-detail-custom .ant-drawer-content{
    padding-right: 9px;
}

.w10{
    width: 100% !important;
    max-width: 100%;
}

.block{
    display: block !important;
}

.action-edit{
    border-radius: 50px;
    padding: 5px;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
}

.relative{
    position: relative;
    text-align: center;
}

.contact-infomation-title{
    white-space: nowrap;
}

.project-name{
    font-weight: bold;
    font-size: 16px;
    margin: 5px;
}

.custom-width{
    width: 100% !important;
}

.custom-width .ant-drawer-content-wrapper{
    width: 100% !important;
}